import request from '@/service/request'

/* 门禁照片审核 权限模板 list
 */
export const listUserPertApi = () => {
  return request.post('/dormitory/fcr/listUserpert')
}

/*  门禁权限模板 更新权限模板
 */
export const saveUserPertApi = (params) => {
  return request.post('/dormitory/fcr/updateUserpert', params)
}

/*  门禁权限模板 更新权限模板
 */
export const updateUserPertApi = () => {
  return request.post('/dormitory/fcr/syncUser')
}
