import request from '@/service/request'

/* 根据token获取门禁照片审核list
* @param {string} dormitoryId
* @param {string} floorNum
* @param {string} headshotStatus
* @param {string} keyword
 */
export const listStuPhotoByTokenApi = (params) => {
  return request.post('/dormitory/fcr/listStuByCurrentManager', params)
}

/* 根据token获取宿管管理的楼栋list
* @param {string} campusId
* @param {string} majorId
 */
export const listDormitoryByTokenApi = (params) => {
  return request.post('/dormitory/dormitoryInfo/listByCurrentManage', params)
}

/* 审核学生大头照 单个审核
* @param {string} stuId
* @param {string} headshotStatus 1-审核中 3-审核通过 2-审核不通过
* @param {string} headshotMsg
 */
export const approveStuHeadshotApi = (params) => {
  return request.post('/stu/stuInfo/auditHeadshot', params)
}

/* 审核学生大头照 批量审核
* @param {array} stuIds
* @param {string} headshotStatus 1-审核中 3-审核通过 2-审核不通过
* @param {string} headshotMsg
 */
export const batchApproveStuHeadshotApi = (params) => {
  return request.post('/stu/stuInfo/auditHeadshotBatch', params)
}
