<template>
  <div class="photo-approve flex-column flex flex-1 oh">
    <div class="flex-not-shrink" style="padding: 20px 20px 0;">
      <!--  加载了楼栋list后赋值 dormitoryId 再显示搜索条 -->
      <view-search-form
        ref="searchFormRef"
        v-if="visibleSearch"
        keyword-placeholder="学生姓名"
        :query-info.sync="tableQuery"
        :rules="rules"
        :tool-list="['keyword']"
        @on-search="renderTable">
        <el-form-item label="管辖楼栋" prop="dormitoryId">
          <tool-tips-group is-flex tips-content='1.去系统设置-用户管理处把账号的"部门"改为公寓服务中心;
2. 到 公寓服务中心-楼况设置 模块，把宿舍楼中的宿舍管理员分配给本账号；'>
            <el-select v-model="tableQuery.dormitoryId" size="small" @change="createFloor" no-match-text="暂未分配楼栋">
              <el-option
                v-for="{dormitoryName, id} in dormBuildList"
                :label="dormitoryName"
                :value="id"
                :key="id" />
            </el-select>
          </tool-tips-group>
        </el-form-item>
        <el-form-item label="楼层" prop="floorNum">
          <el-select v-model="tableQuery.floorNum" clearable filterable size="small">
            <el-option v-for="{label, value} in floorList" :key="value" :label="label" :value="value" />
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态" prop="headshotStatus">
          <el-select v-model="tableQuery.headshotStatus" clearable filterable size="small">
            <el-option v-for="{paramValue, label} in $store.state.systemParam.reviewStatus" :key="paramValue"
                       :label="label" :value="paramValue" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="mb-1">
        <p class="font-grey mt-1" style="display:inline-block;font-size: 13px;">搜索结果: {{ tableData.length }} 人,
          <span>已选中 <b
            style="color:var(--color-primary)">{{ rowCheckList.length }}</b> 人</span></p>
        <div class="header-button fr">
          <el-button type="primary" size="small" @click="updateUserPert" icon="el-icon-refresh">
            同步学生信息到门闸系统
          </el-button>
        </div>
      </div>
    </div>
    <div class="flex-column photo-approve__body flex flex-1 oh">
      <el-table class="select-table" :data="tableData" empty-text="请先选择楼栋、楼层" style="width: 100%" height="100%"
                border stripe
                @selection-change="handleSelectChange">
        <el-table-column type="selection" width="50" align="center" :selectable="handleRowSelectable" />
        <el-table-column prop="stuNo" label="学号" width="150">
          <template v-slot="{row}">
            {{ row.stuNo ? row.stuNo : '未注册' }}
          </template>
        </el-table-column>
        <el-table-column prop="stuName" label="姓名" width="120" />
        <el-table-column prop="apartmentName" label="宿舍" min-width="200">
          <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.dormitoryBedNo"
            >{{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span>
            <span class="font-grey" v-else-if="row.stuStatus===2">已毕业</span>
            <span v-else>未分配</span>
          </template>
        </el-table-column>
        <el-table-column label="高考照片" width="200" align="center">
          <template v-slot="{row}">
            <el-image
              v-if="row.stuExamImg"
              :src="`${$fileUrl}${row.stuExamImg}`"
              :preview-src-list="[`${$fileUrl}${row.stuExamImg}`]" fit="contain" />
            <span class="font-grey" v-else>待上传</span>
          </template>
        </el-table-column>
        <el-table-column label="门禁照片" width="200" align="center">
          <template v-slot="{row}">
            <el-image
              v-if="row.headshot"
              :src="`${$fileUrl}${row.headshot}`"
              :preview-src-list="[`${$fileUrl}${row.headshot}`]" fit="contain" />
            <span class="font-grey" v-else>待上传</span>
          </template>
        </el-table-column>
        <el-table-column label="驳回理由" prop="headshotMsg" min-width="250" />
        <el-table-column label="照片审核" width="140" align="center" fixed="right">
          <template v-slot="{row}">
            <template v-if="row.headshotStatus===1">
              <el-button v-permission="['photo:review']" size="mini" type="success" @click="handleApprove(3, row)">
                通过
              </el-button>
              <el-button v-permission="['photo:review']" size="mini" type="warning" @click="handleApprove(2, row)">
                驳回
              </el-button>
            </template>
            <span
              class="font-grey">{{ row.headshotStatus === 3 ? '已通过' : (row.headshotStatus === 2 ? '已驳回' : '')
              }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="photo-approve__footer flex-not-shrink" v-permission="['photo:review']">
      <el-button type="success" :disabled="!rowCheckList.length" @click="handleBatchApprove(3)">一键通过
      </el-button>
      <el-button type="warning" :disabled="!rowCheckList.length" @click="handleBatchApprove(2)">一键驳回
      </el-button>
    </div>
    <photo-approve-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </div>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import {
  listStuPhotoByTokenApi,
  listDormitoryByTokenApi,
  approveStuHeadshotApi,
  batchApproveStuHeadshotApi
} from '@/api/dorm/dorm-photo-api'
import PhotoApproveDialog from '@/views/pages/dormManage/photo-approve/component/photoApproveDialog.vue'
import { updateUserPertApi } from '@/api/dorm/dorm-accress-api'

export default {
  name: 'photoApprove',
  components: { PhotoApproveDialog },
  mixins: [tableView],
  data () {
    return {
      tableQuery: { // queryInfo
        loadFlag: true,
        dormitoryId: null,
        floorNum: null,
        headshotStatus: 1,
        keyword: null
      },
      visibleSearch: false,
      dormBuildList: [], // 宿管管辖的楼栋 list
      floorList: [], // 楼层list
      rowCheckList: [],
      rules: {
        dormitoryId: { required: true, message: '请选择楼栋', trigger: 'blur' },
        floorNum: { required: true, message: '请选择楼层', trigger: 'blur' }
      }
    }
  },
  async mounted () {
    await this.getDormBuildList()
    // 初始加载,先获取dormitoryId
    if (this.dormBuildList.length) {
      this.tableQuery.dormitoryId = this.dormBuildList[0].id
      await this.createFloor()
    }
    this.visibleSearch = true
  },
  methods: {
    // 获取学生列表
    async renderTable () {
      this.clearTable()
      if (!this.tableQuery.dormitoryId && !this.tableQuery.keyword) {
        this.$message('请先选择楼栋或者输入学生名后再进行搜索。')
        return
      }
      this.loading = true
      try {
        const res = await listStuPhotoByTokenApi(this.tableQuery)
        this.tableData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // 获取楼栋 list
    async getDormBuildList () {
      try {
        const res = await listDormitoryByTokenApi({})
        this.dormBuildList = res.data
      } catch (e) {
      }
    },
    // 生成楼层
    createFloor () {
      const floorAmount = this.dormBuildList.filter((item) => item.id === this.tableQuery.dormitoryId)[0]
        .floorAmount
      this.floorList = []
      for (let i = 0; i < floorAmount; i++) {
        this.floorList.push({ label: i + 1 + '层', value: i + 1 })
      }
    },
    // table 多选控制,获取选中的id
    handleSelectChange (val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ id }) => {
          this.rowCheckList.push(id)
        })
      }
    },
    // table checkbox控制,已经审批过的禁选
    handleRowSelectable (row, rowIndex) {
      return row.headshotStatus === 1
    },
    // 单个审批
    handleApprove (flag, row) {
      if (flag === 2) { // 驳回 打开弹窗
        this.visibleDialog = true
        this.$nextTick(() => {
          this.$refs.dialogRef.formData.id = row.id
          this.$refs.dialogRef.stuName = row.stuName
        })
      } else if (flag === 3) { // 通过
        const _data = {
          headshotStatus: 3,
          id: row.id
        }
        this.$confirm(`<p class="confirm-message">确定要通过 学生-<b class="success">${row.stuName}</b> 的照片审核吗?</p>`, '通过', {
          type: 'success',
          confirmButtonText: '确定通过',
          dangerouslyUseHTMLString: true
        }).then(async () => {
          try {
            await approveStuHeadshotApi(_data)
            this.$message.success('已通过照片审核!')
            await this.renderTable()
          } catch (e) {
          }
        })
      }
    },
    // 批量审批
    handleBatchApprove (flag, row) {
      if (flag === 3) { // 批量通过
        this.$confirm(`<p class="confirm-message">确定要批量通过这 <b class="bg-success">${this.rowCheckList.length}个</b> 学生的门禁照片吗?</p>`, '批量通过', {
          type: 'success',
          confirmButtonText: '确定通过',
          dangerouslyUseHTMLString: true
        }).then(async () => {
          const _data = {
            headshotStatus: flag,
            stuIds: this.rowCheckList
          }
          try {
            await batchApproveStuHeadshotApi(_data)
            this.$message.success('已批量通过照片审核!')
            await this.renderTable()
          } catch (e) {

          }
        })
      } else if (flag === 2) { // 批量驳回 打开弹窗
        this.visibleDialog = true
        this.$nextTick(() => {
          this.$refs.dialogRef.formData.stuIds = this.rowCheckList
        })
      }
    },
    // 向门禁系统请求更新学生数据,
    async updateUserPert () {
      try {
        await updateUserPertApi()
        this.$message.success('已发送请求!')
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss">
.photo-approve {
  background: #fff;
  height: calc(100vh - var(--heard-height));

  .el-radio-group {
    .el-radio-button.is-active.radio-danger {
      .el-radio-button__inner {
        background: var(--color-danger);
        border-color: var(--color-danger);
        box-shadow: -1px 0 0 0 var(--color-danger);
      }
    }

    .el-radio-button.is-active.radio-success {
      .el-radio-button__inner {
        background: var(--color-success);
        border-color: var(--color-success);
        box-shadow: -1px 0 0 0 var(--color-success);
      }
    }
  }

  .photo-approve__footer {
    padding: 10px 0;
    text-align: center;
  }

  .select-table.el-table {
    .el-table__body {
      .el-table__row {
        .el-table-column--selection {
          .cell {
            padding: 0;
          }

          .el-checkbox {
            width: 100%;
            padding: 110px 0;
          }
        }
      }
    }
  }
}
</style>
