<template>
  <el-dialog
    title='审核-驳回'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='460px'
    @close='cancel'>
    <el-form :model='formData'>
      <el-form-item label='请输入驳回理由' props=''>
        <el-input type='textarea' v-model='formData.headshotMsg' />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click='cancel'>取消</el-button>
      <el-button type='warning' @click='handleBatchReject'>确认驳回</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { approveStuHeadshotApi, batchApproveStuHeadshotApi } from '@/api/dorm/dorm-photo-api'

export default {
  name: 'photoApproveDialog',
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        stuIds: [],
        headshotStatus: 2,
        headshotMsg: ''
      },
      stuName: ''
    }
  },
  mounted () {
  },
  methods: {
    handleBatchReject () {
      const _msg = this.formData.stuIds.length ? `这 -<b class="bg-warning">${this.formData.stuIds.length}个</b> 学生` : `学生-<b class="warning">${this.stuName}</b>`
      this.$confirm(`<p class="confirm-message">是否确认驳回${_msg}的门禁照片?</p>`, '批量驳回', {
        confirmButtonText: '确定驳回',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        if (this.formData.stuIds.length) { // 批量驳回
          try {
            await batchApproveStuHeadshotApi(this.formData)
            this.closed()
          } catch (e) {

          }
        } else { // 单个驳回
          try {
            await approveStuHeadshotApi(this.formData)
            this.closed()
          } catch (e) {

          }
        }
      }).catch(() => {
        this.$message.info('已取消操作.')
        this.close()
      })
    }
  }
}
</script>
